
import { KwikDialog } from 'kwik-vue'
import Vue, { PropType } from 'vue'
import { dispatchBuyMachine, dispatchGetContamination } from '../store/actions'
import { ICompanyMachine } from '../types'

export default Vue.extend({
  name: 'FFCompanyMachineCard',
  components: { KwikDialog },
  props: {
    companyMachine: {
      type: Object as PropType<ICompanyMachine>
    }
  },
  data () {
    return {
      showBuyMachine: false,
      showCQResult: false,
      cqMessage: '',
      breadcrumbs: [
        { text: 'Food Factory', disabled: true },
        { text: 'Parco macchine', disabled: false }
      ]
    }
  },
  methods: {
    async buy () {
      await dispatchBuyMachine(this.$store, this.companyMachine.id)
      this.showBuyMachine = false
      this.$emit('buyed')
    },
    async cq () {
      debugger
      const result = await dispatchGetContamination(this.$store, this.companyMachine.id)
      this.cqMessage = 'Livello di contaminazione: ' + result + '%'
      this.showCQResult = true
    },
    closeCq () {
      this.cqMessage = ''
      this.showCQResult = false
    }
  }
})
